<template>
  <!-- Bottom sheet to get if there is any outstanding payments -->
  <!-- v-if="Object.hasOwnProperty.call(bookingDetails, 'outStandingPaymentDetails') && Object.hasOwnProperty.call(bookingDetails.outStandingPaymentDetails , 'status') && bookingDetails.outStandingPaymentDetails.status" -->
  <div>
    <v-bottom-sheet v-model="outStandingPayments" width="100%" height="30px" persistent style="
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
      " scrollable>

      <v-card width="100%" class="pa-0" style="
          border-top-left-radius: 25px !important;
          border-top-right-radius: 25px !important;
        ">
        <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
        <v-card-text v-if="!loading" class="pa-0">
          <v-container fluid pa-0>
            <!-- alert icon png image from figma -->
            <v-row no-gutters>
              <v-col class="text-center mt-4 pb-3">
                <v-img width="48px" height="48px" style="margin: auto;" src="@/assets/alert.png">
                </v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-center black--text" cols="12">
                <p style="font-weight: bold; margin-bottom: 4px;font-size: 20px;">
                  Your Unpaid Parking
                </p>
                <p style="font-size: 12px" class="mb-3">
                  Your previous payment wasn't processed.
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-card flat class="pa-3 text-center ml-3 mr-3"
                  style="border-radius: 10px; background: rgb(242, 85, 92, 0.1)">
                  <v-row>
                    <!-- total payable value -->
                    <v-col class="text-center">
                      <p class="mb-10 text-left" style="font-size: 12px; font-weight: 400">
                        Amount Due
                      </p>
                      <p style="
                        font-weight: 600;
                        font-size: 30px;
                        margin-bottom: 8px;
                      " class="mt-3">
                        <span style="font-weight: 300">$ </span>{{ totalPayable }}
                      </p>
                      <p style="
                        font-size: 12px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 17.28px;
                      " class="px-5">
                        Includes Service Fee (${{ serviceFee }}). State and Local taxes
                        (${{ serviceTax }})
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="pb-3 mt-6"
              v-if="bookingId != null && bookingId != undefined && bookingId != '' && bookingId != '-'">
              <v-col style="display: flex;" cols="8">
                <p style="font-weight: 14px; font-weight: 600; margin-bottom: 0" class="pl-4 ">
                  <span class="black--text" style="font-weight: bold;">Order Number :</span>
                  <span @click="navigateToURL()"
                    style="color: grey !important; font-weight: 400 !important; text-decoration: underline;">
                    {{ bookingId }}</span>
                </p>
                <v-btn icon x-small class="mb-1">
                  <v-icon class="material-icons-outlined " x-small color="#f2555c" @click="copyURL()">copy</v-icon>
                  <v-snackbar v-model="copySnackBar" timeout="550" class="mb-15">
                    <p style="text-transform: none !important;">{{ copyText }} </p>
                  </v-snackbar>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mx-4"></v-divider>
            <v-row no-gutters class="mt-3 mb-4">
              <v-col class="text-left pl-4" cols="7">
                <p class="grey-detail-text" style="margin-bottom: 0">
                  Last Parked At
                </p>
                <p class="black-detail-text">{{ lastParkedAt }}</p>
              </v-col>
              <v-col class="pl-6 text-left pb-4" cols="5">
                <p class="grey-detail-text" style="margin-bottom: 0">Plate</p>
                <p class="black-detail-text">{{ plate }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-left pl-4 pb-3" cols="7">
                <p class="grey-detail-text" style="margin-bottom: 0">
                  Entry Date & Time
                </p>
                <p class="black-detail-text">{{ parkedFrom[0] }}<sup>th</sup> {{ parkedFrom[1] }}</p>
              </v-col>

              <v-col class="pl-6 text-left pb-4" cols="5">
                <p class="grey-detail-text" style="margin-bottom: 0">
                  Duration
                </p>
                <p class="black-detail-text">{{ duration }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-card flat>
                  <v-card-text style="border-radius: 10px !important;">
                    <v-img v-if="lprImageComputed" aspect-ratio="1" class="grey lighten-2" lazy-src="" width="100%"
                      height="162px" style="border-top-left-radius: 10px; border-top-right-radius: 10px;"
                      :src="lprImagePath" transition="fade-transition">
                      <template v-slot:placeholder>
                        <v-row align="center" class="fill-height ma-0" justify="center">
                          <v-skeleton-loader class="mx-auto" width="100%" type="image"></v-skeleton-loader>
                        </v-row>
                      </template>
                    </v-img>
                    <v-container pa-0 fluid :class="{'rounded-lg' : !lprImageComputed}" style="background: rgba(246, 246, 246);padding-top: 14px !important; border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;">
                      <v-row no-gutters>
                        <v-col class="pl-3 text-left pb-4" cols="5">
                          <!-- custom css classes grey-detail-text and black detail-text -->
                          <p class="grey-detail-text" style="margin-bottom: 0">Contact No</p>
                          <p class="black-detail-text">{{ '+' + contactNo }}</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider v-if="!loading" style="height: 90px;"></v-divider>


        <v-card-actions v-if="!loading" style="display: inline !important;">
          <v-row no-gutters>
            <v-col class="text-center px-12">
              <p style="font-size: 12px !important;" class="exit-detail-text mt-3 pb-4">
                You won't be able to exit the garage until your payment is on
                file
              </p>
            </v-col>

          </v-row>
          <v-row no-gutters class=" pb-4">
            <v-col :cols="payCardCols" class="text-left pl-3 pr-3">
              <v-btn width="100%" style="
                  font-weight: 500;
                  background-color: #f2555c !important;
                  font-size: 16px !important;
                  letter-spacing: normal !important;
                  height: 37px !important;
                " elevation="20" rounded class="white--text no-upper-case" @click="addCard()">
                Pay With Card</v-btn>
            </v-col>

            <v-col cols="6" class="text-right pr-3" v-if="iosDevice &&
              Object.hasOwnProperty.call(outStandingBookingDetails, 'booking') &&
              Object.hasOwnProperty.call(
                outStandingBookingDetails.booking,
                'paymentServices'
              ) &&
              Object.hasOwnProperty.call(
                outStandingBookingDetails.booking.paymentServices,
                'apple_pay'
              ) &&
              outStandingBookingDetails.booking.paymentServices.apple_pay == '1' && Object.hasOwnProperty.call(outStandingBookingDetails.booking, 'bid') && Object.hasOwnProperty.call(outStandingBookingDetails, 'user') && Object.hasOwnProperty.call(outStandingBookingDetails.user, 'contact') && Object.hasOwnProperty.call(outStandingBookingDetails.booking, 'startAt') && Object.hasOwnProperty.call(outStandingBookingDetails, 'zone') && Object.hasOwnProperty.call(outStandingBookingDetails.zone, 'locationCode')
            ">
              <apple-pay id="outStandingPayments" width="100%" height="37px"
                :styling="{ fontSize: '15px', fontWeight: '700' }" :typeOfBooking="typeOfBooking" :ios-device="iosDevice &&
                  Object.hasOwnProperty.call(outStandingBookingDetails, 'booking') &&
                  Object.hasOwnProperty.call(
                    outStandingBookingDetails.booking,
                    'paymentServices'
                  ) &&
                  Object.hasOwnProperty.call(
                    outStandingBookingDetails.booking.paymentServices,
                    'apple_pay'
                  ) &&
                  outStandingBookingDetails.booking.paymentServices.apple_pay == '1' && Object.hasOwnProperty.call(outStandingBookingDetails.booking, 'bid') && Object.hasOwnProperty.call(outStandingBookingDetails, 'user') && Object.hasOwnProperty.call(outStandingBookingDetails.user, 'contact')
                  " :bookingId="outStandingBookingDetails.booking.bid"
                :contact="outStandingBookingDetails.user.contact"
                :locationCode="outStandingBookingDetails.zone.locationCode"
                :startAt="outStandingBookingDetails.booking.startAt"></apple-pay>
            </v-col>
            <v-col cols="6" v-if="!iosDevice &&
              Object.hasOwnProperty.call(outStandingBookingDetails, 'booking') &&
              Object.hasOwnProperty.call(
                outStandingBookingDetails.booking,
                'paymentServices'
              ) &&
              Object.hasOwnProperty.call(
                outStandingBookingDetails.booking.paymentServices,
                'google_pay'
              ) &&
              outStandingBookingDetails.booking.paymentServices.google_pay == '1' && Object.hasOwnProperty.call(outStandingBookingDetails.booking, 'bid') && Object.hasOwnProperty.call(outStandingBookingDetails, 'user') && Object.hasOwnProperty.call(outStandingBookingDetails.user, 'contact') && Object.hasOwnProperty.call(outStandingBookingDetails.booking, 'startAt') && Object.hasOwnProperty.call(outStandingBookingDetails, 'zone') && Object.hasOwnProperty.call(outStandingBookingDetails.zone, 'locationCode')
            ">
              <google-pay id="outStandingPayments" width="100%" height="37px"
                :styling="{ fontSize: '15px', fontWeight: '700' }" :bookingId="outStandingBookingDetails.booking.bid"
                :contact="outStandingBookingDetails.user.contact"
                :locationCode="outStandingBookingDetails.zone.locationCode"
                :startAt="outStandingBookingDetails.booking.startAt" :typeOfBooking="typeOfBooking"></google-pay>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
import APIHelper from "../apiHelper";
import GooglePay from "../views/GooglePay.vue";
import { EventBus } from "@/lib/EventBus";
import ApplePay from "@/views/ApplePay.vue";
import moment from 'moment'
import { mapGetters } from "vuex";
import { differenceInMinutes } from "date-fns";

export default {
  data: () => ({ loading: true, copySnackBar: false, copyText: '', env: process.env.VUE_APP_ENVIRONMENT }),
  components: {
    ApplePay,
    GooglePay,
  },
  computed: {
    lprImageComputed() {
      if (window.location.href.includes('zcode')) {
        return this.outStandingLPRImage != undefined && this.outStandingLPRImage != null && this.outStandingLPRImage != '' && this.outStandingLPRImage.length > 0
      }
      return Object.hasOwnProperty.call(this.bookingDetails, 'outStandingPaymentDetails') && Object.hasOwnProperty.call(this.bookingDetails.outStandingPaymentDetails, 'lprImage') && this.bookingDetails.outStandingPaymentDetails.lprImage != null && this.bookingDetails.outStandingPaymentDetails.lprImage != undefined && this.bookingDetails.outStandingPaymentDetails.lprImage.length > 0
    },
    lprImagePath() {
      if (this.outStandingLPRImage != undefined && this.outStandingLPRImage != null && this.outStandingLPRImage != '' && this.outStandingLPRImage.length > 0) {
        if (this.outStandingLPRImage.length == 1) {
          return this.outStandingLPRImage[0].image_path
        }
        let imgPath = this.outStandingLPRImage.filter((a) => a.gateType == 'OUT')
        return imgPath[0].image_path
      }
      let lprImages = this.bookingDetails?.outStandingPaymentDetails?.lprImage
      if (lprImages.length == 1) {
        return lprImages[0].image_path
      }
      let imgPath = lprImages.filter((a) => a.gateType == 'OUT')
      return imgPath[0].image_path
    },
    // condition to check whether the apple or google pay is disabled and expanding the cols to 12 according to the respected values
    payCardCols() {
      if (this.iosDevice && Object.hasOwnProperty.call(this.outStandingBookingDetails, 'booking') &&
        Object.hasOwnProperty.call(
          this.outStandingBookingDetails.booking,
          'paymentServices'
        ) &&
        Object.hasOwnProperty.call(
          this.outStandingBookingDetails.booking.paymentServices,
          'apple_pay'
        ) &&
        this.outStandingBookingDetails.booking.paymentServices.apple_pay != '1') {
        return '12'
      }
      else if (!this.iosDevice &&
        Object.hasOwnProperty.call(this.outStandingBookingDetails, 'booking') &&
        Object.hasOwnProperty.call(
          this.outStandingBookingDetails.booking,
          'paymentServices'
        ) &&
        Object.hasOwnProperty.call(
          this.outStandingBookingDetails.booking.paymentServices,
          'google_pay'
        ) &&
        this.outStandingBookingDetails.booking.paymentServices.google_pay != '1') {
        return '12'

      }
      return '6'
    },
    ...mapGetters({
      bookingDetails: "getterBookingDetails",
      outStandingBookingDetails: "getterOutStandingBookingDetails",
    }),
    serviceFee() {
      return this.outStandingBookingDetails?.booking?.estimatedFee || ''
    },
    serviceTax() {
      return this.outStandingBookingDetails?.booking?.estimatedTax || ''
    },
    bookingId() {
      if (window.location.href.includes('zcode')) {
        return this.bookingDetails?.booking?.linkedTxnRefno || "-"
      }
      return this.bookingDetails?.outStandingPaymentDetails?.linkedTxnRefno || "-";
    },
    plate() {
      return this.outStandingBookingDetails?.booking?.vehicleNo || "-";
    },
    contactNo() {
      return this.outStandingBookingDetails?.user?.contact || "-";
    },
    lastParkedAt() {
      return (this.outStandingBookingDetails?.zone?.zoneName || "-") + " ( " + this.outStandingBookingDetails?.zone?.zcode + " )" || "-"
    },
    parkedFrom() {

      return this.outStandingBookingDetails?.booking?.entrytime
        ?
        [moment.unix(this.outStandingBookingDetails?.booking?.entrytime).utc().format("MMM' D"), moment.unix(this.outStandingBookingDetails?.booking?.entrytime).utc().format(", hh:mm A ")]
        : "-";

    },
    duration() {
      return this.outStandingBookingDetails?.booking?.entrytime &&
        this.outStandingBookingDetails?.booking?.exittime
        ? differenceInMinutes(
          new Date(moment.unix(this.outStandingBookingDetails?.booking?.exittime).utc()),
          new Date(moment.unix(this.outStandingBookingDetails?.booking?.entrytime).utc())
        ) + ' min'
        : "-";
    },
    totalPayable() {
      return Object.hasOwnProperty.call(
        this.outStandingBookingDetails,
        "booking"
      ) &&
        Object.hasOwnProperty.call(
          this.outStandingBookingDetails.booking,
          "estimatedTotal"
        ) &&
        this.outStandingBookingDetails.booking.estimatedTotal != null &&
        this.outStandingBookingDetails?.booking?.estimatedTotal
        ? this.outStandingBookingDetails.booking.estimatedTotal
        : "";
    },
    getSiteKey(){
      return  process.env.VUE_APP_CAPTCHA_SITE_KEY
    }
  },
  watch: {
    outStandingPayments() {
      if (this.outStandingPayments == true) {
        this.getOutStandingBookingDetails();
      }
    },
  },
  mounted() {
    var self = this;
    //used to get the outstanding payments booking for the CICO type of transaction
    EventBus.$on("SQUARE_PAY_OUTSTANDING_CHECKEDIN", async (tokenResult, verfiedToken, paymentType) => {
      await self.squarePay(tokenResult, verfiedToken, paymentType, 'cico')
    })
    //used to get the outstanding payment booking for the OD Validation type of transaction
    EventBus.$on("SQUARE_PAY_OUTSTANDING_ODV", async (tokenResult, verfiedToken, paymentType) => {
      await self.squarePay(tokenResult, verfiedToken, paymentType, 'odv')
    })
  },
  destroyed() {
    EventBus.$off('SQUARE_PAY_OUTSTANDING_CHECKEDIN');
    EventBus.$off("SQUARE_PAY_OUTSTANDING_ODV")
  },
  methods: {
    /**
       * @method squarePay - This method is used to create a transaction using Apple or Google Pay.
       * @param {*} tokenResult - represents the token result of the square apple or gpay
       * @param {*} verfiedToken - represents the verification of the token
       * @param {*} paymentType  - represents the payment type whether apple or google Pay
       * @param {*} typeOfTxn - represents the type of transaction whether it is odv or cico etc... 
       */
    async squarePay(tokenResult, verfiedToken, paymentType, typeOfTxn) {
      let cardData = {};
      if (
        this.bookingDetails != null &&
        Object.hasOwnProperty.call(this.bookingDetails, "zone")
      ) {
        cardData = {
          userDevice: "2",
          orderType: "3",
          entry: [
            {
              locationCode: this.outStandingBookingDetails?.zone?.locationCode,
              startAt: this.outStandingBookingDetails?.booking?.startAt,
              quantity: "1",
            },
          ],
          paymentType: paymentType,
          billingDetails: tokenResult?.details?.billing,
          verification_token: verfiedToken,
          source_id: tokenResult.token,
          phoneNumber: "+" + this.outStandingBookingDetails.user.contact,
          email: tokenResult?.details?.shipping?.contact?.email || "",
          isOutstandingPayment: true,
          captchaVerify: {
            token: this.generateRandomToken(),
            sitekey: this.getSiteKey,
            legacy: true
          }
        };
        try {
          var addCard = await APIHelper(
            "POST",
            "/api/v1/payment/addCard/" + this.outStandingBookingDetails.booking.bid,
            cardData
          );
          if (addCard?.data?.status == false) {
            this.gPayBtnLoading = false;
            this.alertMsg =
              addCard?.data?.message || "Error in authorizing the payment";
            this.alertIcon = "mdi-alert-outline";
            this.alertDialog = true;
          } else {
            this.gPayBtnLoading = false;
            //url segment is app mixin computed property 
            if (typeOfTxn == 'odv') {
              await this.$router.replace({ path: "/thankyouloading" });
              return;
            }
            else {
              let url =
                window.location.origin + '/g/' + this.bookingDetails?.booking?.bid;
              window.location.replace(url);
            }
          }
        } catch (error) {
          this.gPayBtnLoading = false;
          console.log(error);
        }
      }
    },
    // Method to navigate to a the pending p2p url
    navigateToURL() {
      let url = `${process.env.VUE_APP_WEB_APP_URL}${this.outStandingBookingDetails?.booking?.bid}`;
      window.open(url, '_blank');
    },
    //Used to copy the url content to clipboard
    async copyURL() {
      try {
        let url = `${process.env.VUE_APP_WEB_APP_URL}${this.outStandingBookingDetails?.booking?.bid}`;
        await navigator.clipboard.writeText(url);
        //Order link copied to your clipboard
        this.copyText = 'copied to clipboard'
        this.copySnackBar = true
      } catch ($e) {
        // Copying to clipboard failed.Please retry or refresh the page -->  from ui
        this.copyText = 'Copy unsuccessful, Please try again'
        this.copySnackBar = true
      }
    },
    //Navigating to the add payment screeen
    addCard() {
      this.postToLoggerAPI({
        phone: this.outStandingBookingDetails.user.contact,
        refId: this.bookingId,
        statusText: "Parker clicked on ADD CREDIT CARD btn.",
      });
      let parkingDisclosure = {
        content: this.outStandingBookingDetails?.zone?.mySpotSettings?.parkingDisclosures,
      };
      let odOutStanding = window.location.href.includes("zcode") ? true : false
      this.$router.replace({
        name: "AddPayment",
        params: { parkingDisclosure, outStandingPayments: true, odOutStanding: odOutStanding },
      });
    },
    //To fetch the outstanding payment transaction details
    async getOutStandingBookingDetails() {
      try {
        var bookingId = "";
        if (window.location.href.includes("zcode")) {
          bookingId = this.bookingDetails?.booking?.bid
        }
        else {
          bookingId = this.bookingDetails?.outStandingPaymentDetails?.bid
        }
        this.loading = true;
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" +
          bookingId
        );
        this.$store.commit(
          "SET_OUTSTANDING_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  props: {
    outStandingPayments: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    outStandingLPRImage: {
      type: Array,
      default: () => {
        return []
      }
    },
    iosDevice: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    typeOfBooking: {
      type: String,
      default: () => {
        return 'OutStandingCheckin'
      }
    }
  },
};
</script>
<style scoped>
p {
  margin-bottom: 0;
}

.grey-detail-text {
  font-size: 14px;
  font-weight: 500;
}

.black-detail-text {
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.exit-detail-text{
  font-size: 14px;
}
</style>
